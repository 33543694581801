<template>
  <div class="menu-form tickets">
    <Header :withMerchant="false" />
    <section class="main">
      <Event :error="error" />
    </section>
  </div>
</template>

<script>
import Event from "@/components/tickets/Event";
import getMenuInfo from "@/mixins/getMenuInfo";
import setRoute from "@/mixins/setRoute";
import Header from "@/components/Header";

export default {
  mixins: [getMenuInfo, setRoute],
  components: {
    Event,
    Header,
  },
  data: () => ({
    error: null,
  }),
  created() {
    localStorage.setItem(
      "templateId",
      JSON.stringify(this.$route.query.template)
    );
  },
  watch: {
    "$route.query.error"(val) {
      this.error = val;
    },
  },
  mounted() {
    this.error = this.$route.query.error;
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/pages/tickets.scss";
</style>
